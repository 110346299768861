import React, { useState } from "react";
import Layout from "../components/layout.js";
import { colors } from "../style/theme.js";
import SEO from "components/seo";
import { mq } from "../utils/helper";
import faqs from "../../data/faqs.js";

function HelpDesk() {
  return (
    <div css={{ backgroundColor: colors.dark }}>
      <SEO title="Help desk" />
      <img
        src="/assets/engage-background.gif"
        css={{
          position: "absolute",
          top: "15%",
          left: "-15%",
          width: "100%",
          height: "auto",
          zIndex: 0,
          opacity: 0.45
        }}
      />
      <Layout css={{ zIndex: 2 }}>
        <div
          css={{
            maxWidth: "1200px",
            margin: "100px auto",
            position: "relative",
            zIndex: 2,
            [mq[1]]: {
              margin: "4rem 2rem"
            }
          }}
        >
          <h2
            css={{
              color: colors.primary,
              margin: 0,
              marginBottom: "100px",
              fontSize: "48px",
              display: "block"
            }}
          >
            Help Desk
          </h2>
          {faqs.map(faq => (
            <FAQ faq={faq} />
          ))}
        </div>
      </Layout>
    </div>
  );
}

function FAQ({ faq }) {
  const [symbol, setSymbol] = useState("+");

  const toggleSymbol = () => (symbol === "+" ? setSymbol("-") : setSymbol("+"));

  return (
    <details css={{ color: "white" }}>
      <summary
        css={{
          fontSize: "40px",
          fontWeight: "bold",
          marginBottom: "1.3rem",
          color: colors.primary,
          cursor: "pointer",
          "::-webkit-details-marker": {
            display: "none"
          },
          [mq[1]]: {
            fontSize: "20px"
          }
        }}
        onClick={toggleSymbol}
      >
        {symbol} {faq.question}
      </summary>
      <div
        css={{
          color: "white",
          fontSize: "25px",
          paddingLeft: "3rem",
          marginBottom: "1.5rem",
          "& a": {
            color: colors.primary
          },
          [mq[1]]: {
            fontSize: "16px",
            paddingLeft: "1.5rem"
          }
        }}
        dangerouslySetInnerHTML={{ __html: faq.answer }}
      />
    </details>
  );
}

export default HelpDesk;
